@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@layer base {
  html, body {
    font-family: 'Noto Sans', sans-serif;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.highlightwordlargered::after {
  content: '';
  background-color: #ff5940;
  height: 30%;
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 8%;
  z-index: -1;
}
.highlightwordlargered {
  position: relative;
}
